document.querySelectorAll('.Faq-itemQuestion ').forEach(function (question) {
  question.addEventListener('click', (event) => {
    const detailsId = event.target.getAttribute('data-id')
    const details = document.querySelector(detailsId)

    if (!details.open) {
      document
        .querySelectorAll('.Faq-item:not(' + detailsId + ')')
        .forEach(function (item) {
          item.open = false
        })
    }
  })
})
