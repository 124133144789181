import { createApp } from 'vue'
import WatchListIndicator from './WatchListIndicator.vue'

const WatchListIndicatorElement = document.querySelector('.WatchListIndicator')

if (WatchListIndicatorElement) {
  createApp(WatchListIndicator, {
    id: parseInt(WatchListIndicatorElement.getAttribute('data-id')),
    type: WatchListIndicatorElement.getAttribute('data-type'),
  }).mount(WatchListIndicatorElement)
}
