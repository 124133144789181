<script setup>
import { ref, onMounted, watch } from 'vue'

const currentAnswers = ref([])

defineEmits(['answer', 'continue'])

const props = defineProps({
  questionId: {
    type: String,
    default: '',
  },
  question: {
    type: String,
    default: '',
  },
  answers: {
    type: Object,
    default: undefined,
  },
  feedback: {
    type: Object,
    default: undefined,
  },
  pending: {
    type: Boolean,
    default: false,
  },
})

const feedbackClasses = function (answerId) {
  if (props.feedback == undefined) return

  let isCorrect = false
  let hasFeedback = false

  if (props.feedback.answerFeedback[answerId]) {
    isCorrect = props.feedback.answerFeedback[answerId].isCorrect
    hasFeedback = props.feedback.answerFeedback[answerId].feedback != false
  }

  return {
    [`QuizMultipleChoice-answer--wrong`]: !isCorrect && hasFeedback,
    [`QuizMultipleChoice-answer--correct`]: isCorrect && hasFeedback,
  }
}

const getFeedback = function (answerId) {
  if (props.feedback == undefined) return false

  if (
    props.feedback.answerFeedback[answerId] &&
    props.feedback.answerFeedback[answerId].feedback != false
  ) {
    return props.feedback.answerFeedback[answerId].feedback
  }

  return false
}

const getFeedbackLabel = function (answerId) {
  if (props.feedback == undefined) return false

  if (
    props.feedback.answerFeedback[answerId] &&
    props.feedback.answerFeedback[answerId].feedback != false
  ) {
    const feedback = props.feedback.answerFeedback[answerId]

    if (feedback.isCorrect) return 'Richtig!'

    if (!feedback.isCorrect && feedback.answerCorrectness == 'correct')
      return 'Das wäre richtig gewesen.'

    if (!feedback.isCorrect && feedback.answerCorrectness == 'incorrect')
      return 'Falsch!'
  }

  return false
}

const toggleAnswerFeedback = function (answerId) {
  feedbackRef.value.answerFeedback[answerId].expanded =
    !feedbackRef.value.answerFeedback[answerId].expanded
}

onMounted(() => {
  currentAnswers.value = []
})

const feedbackRef = ref(undefined)

watch(
  () => props.feedback,
  (newValue) => {
    feedbackRef.value = newValue
  },
)
</script>

<template>
  <div class="QuizMultipleChoice info-text">
    <h3>{{ props.question }}</h3>

    <ul class="QuizMultipleChoice-answers">
      <li
        v-for="answer in props.answers"
        :key="answer.id + questionId"
        :class="feedbackClasses(answer.id)"
        class="QuizMultipleChoice-answer"
      >
        <input
          :id="'answer-' + answer.id"
          :key="answer.id + questionId"
          v-model="currentAnswers"
          type="checkbox"
          class="QuizMultipleChoice-answerInput"
          :disabled="feedback"
          name="answer[]"
          :value="answer.id"
        />
        <label
          class="QuizMultipleChoice-answerLabel"
          :for="'answer-' + answer.id"
        >
          <span class="QuizMultipleChoice-answerLabelCheckmark"></span>
          <span
            class="QuizMultipleChoice-answerLabelText"
            :class="{
              'QuizMultipleChoice-answerLabelText--feedback': getFeedbackLabel(
                answer.id,
              ),
            }"
          >
            <span>{{ answer.answer }}</span>

            <button
              v-if="getFeedbackLabel(answer.id)"
              class="QuizMultipleChoice-answerLabelFeedbackShort"
              :aria-controls="'feedback' + answer.id"
              :aria-expanded="feedbackRef.answerFeedback[answer.id].expanded"
              @click="toggleAnswerFeedback(answer.id)"
            >
              {{ getFeedbackLabel(answer.id) }}

              <i class="las la-question-circle"></i>
            </button>
          </span>
        </label>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="getFeedback(answer.id)"
          :id="'feedback' + answer.id"
          :hidden="!feedbackRef.answerFeedback[answer.id].expanded"
          class="QuizMultipleChoice-answerFeedback"
          v-html="getFeedback(answer.id)"
        ></div>
        <!--eslint-enable-->
      </li>
    </ul>

    <p>Mehrfachantworten möglich!</p>

    <button
      v-if="!feedback"
      :disabled="props.pending"
      class="button blue-button"
      @click="
        $emit('answer', {
          question: questionId,
          answers: JSON.parse(JSON.stringify(currentAnswers)),
        })
      "
    >
      Antwort(en) prüfen
    </button>

    <button
      v-if="feedback && !pending"
      class="button blue-button"
      @click="$emit('continue')"
    >
      Weiter
    </button>
  </div>
</template>
