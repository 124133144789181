<script setup>
import { reactive, watch, toRefs, onMounted } from 'vue'

import 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/models/dom/model'
import 'tinymce/skins/ui/oxide/content.inline'
import 'tinymce/skins/ui/oxide/skin.shadowdom'
import 'tinymce/skins/ui/oxide/skin'

import Editor from '@tinymce/tinymce-vue'

const emit = defineEmits(['update:modelValue'])

const currentText = reactive({
  value: undefined,
})

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  labelInfo: {
    type: String,
    default: '',
    required: false,
  },
  required: {
    type: Boolean,
    default: true,
    required: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Boolean],
    default: '',
    required: false,
  },
  formName: {
    type: String,
    default: '',
  },
})

const reactiveProps = toRefs(props)

const updateText = (newValue) => {
  currentText.value = newValue
}

const onInput = (event, editor) => {
  emit('update:modelValue', editor.getContent())
}

onMounted(() => {
  updateText(props.modelValue)
})

watch(
  () => reactiveProps.modelValue,
  (newValue) => {
    updateText(newValue)
  },
)
</script>

<template>
  <div class="RichTextEditor">
    <Editor
      :id="props.name.replace('.', '_')"
      ref="currentFieldRef"
      v-model="currentText.value"
      :initial-value="currentText.value"
      :disabled="isDisabled"
      class="RichTextEditor-editor"
      :init="{
        license_key: 'gpl',
        inline: true,
        menubar: false,
        plugins: 'lists link',
        toolbar: 'undo redo | bold bullist link unlink',
        modelEvents: 'change',
      }"
      @change="onInput"
    />
    <textarea
      v-model="currentText.value"
      class="hidden"
      :name="props.name"
    ></textarea>
  </div>
</template>
