<script setup>
import { ref, onMounted, nextTick, computed } from 'vue'
import axios from 'axios'
import ConfettiExplosion from 'vue-confetti-explosion'

// eslint-disable-next-line
import QuizMultipleChoice from './QuestionTypes/QuizMultipleChoice.vue'
// import {} from 'vue'

const currentQuestionIndex = ref(0)
const currentQuestion = ref(undefined)
const currentFeedback = ref(undefined)
const progress = ref([])
const points = ref(0)
const quizDone = ref(false)
const pending = ref(false)

const confettiVisible = ref(false)

const props = defineProps({
  quizId: {
    type: String,
    default: '',
  },
})

const restart = function () {
  currentQuestionIndex.value = 0
  currentFeedback.value = undefined
  progress.value = []
  points.value = 0
  quizDone.value = false
  pending.value = false
  currentQuestion.value = questions[currentQuestionIndex.value]
}

const getQuestionComponent = function (type) {
  if (type == 'quiz_multipleChoice') return QuizMultipleChoice
  return false
}

const checkAnswer = function (data) {
  const tokenName = 'X-' + document.csrfTokenName
  const tokenValue = document.csrfTokenValue

  pending.value = true

  axios
    .post(
      document.jsVariables.apiBaseUrl + 'checkAnswer',
      {
        quiz: props.quizId,
        question: data.question,
        answers: data.answers,
      },
      {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          [tokenName]: tokenValue,
        },
      },
    )
    .then((response) => {
      pending.value = false
      if (response.status == 200) {
        progress.value.push(response.data)
        points.value = points.value + response.data.points

        currentFeedback.value = response.data
      }
    })
}

const confettiAction = async () => {
  confettiVisible.value = false
  await nextTick()
  confettiVisible.value = true
}

const nextQuestion = function () {
  currentFeedback.value = undefined

  if (progressPercent.value == 100) {
    currentQuestion.value = undefined
    quizDone.value = true
    confettiAction()
  }

  if (questions.length > currentQuestionIndex.value + 1) {
    currentQuestionIndex.value = currentQuestionIndex.value + 1
    currentQuestion.value = questions[currentQuestionIndex.value]
  } else {
    currentQuestion.value = undefined
  }
}

const quizData = document.jsVariables.quizData[props.quizId]
const questions = quizData.questions

onMounted(() => {
  currentQuestion.value = questions[currentQuestionIndex.value]
})

const progressPercent = computed(() => {
  return ((currentQuestionIndex.value + 1) / questions.length) * 100
})
</script>

<template>
  <div class="QuizModule">
    <component
      :is="getQuestionComponent(currentQuestion.type)"
      v-if="currentQuestion"
      :key="'question-' + currentQuestion.id"
      class="QuizModule-question"
      :question-id="currentQuestion.id"
      :question="currentQuestion.title"
      :answers="currentQuestion.data.answers"
      :feedback="currentFeedback"
      :pending="pending"
      @answer="checkAnswer($event)"
      @continue="nextQuestion()"
    >
    </component>

    <div v-if="quizDone" class="QuizModule-done">
      <div class="info-text">
        <h2>Herzlichen Glückwunsch!</h2>
        <p>
          Sie haben das Quiz abgeschlossen und dabei
          <b>{{ points }} Punkte</b> gesammelt.
        </p>

        <button class="button" @click="restart()">Erneut versuchen</button>
      </div>
    </div>

    <div v-if="!quizDone" class="QuizModule-progress">
      <div class="QuizModule-progressBar">
        <div
          class="QuizModule-progressIndicator"
          :style="{ width: progressPercent + '%' }"
        ></div>
      </div>
      <div class="QuizModule-progressDetails">
        <span
          >Frage: {{ currentQuestionIndex + 1 }} / {{ questions.length }}</span
        >
        <span>{{ points }} / {{ quizData.pointsMax }} Punkte(n)</span>
      </div>
    </div>

    <ConfettiExplosion
      v-if="confettiVisible"
      :stage-width="2000"
      :stage-height="2000"
      :duration="5000"
      :colors="[
        'var(--blue)',
        '#fca49c',
        'var(--blue-semidark)',
        'var(--grey-light2)',
        'var(--blue-dark)',
      ]"
      class="QuizModule-confetti"
    />

    <button
      class="QuizModule-confettiButton"
      title="Konfetti-Kanone zünden"
      @click="confettiAction"
    >
      <i class="las la-bomb"></i>
    </button>
  </div>
</template>
