import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import PortalVue from 'portal-vue'
import { createApp } from 'vue'
import LearningScenarios from './LearningScenarios.vue'
import emitter from '@/mitt'

const LearningScenariosDialog = document.querySelector(
  '.LearningScenarios-dialog',
)

if (LearningScenariosDialog) {
  const app = createApp(LearningScenarios)
  app.use(PortalVue)
  app.mount(LearningScenariosDialog)
}

Fancybox.defaults.l10n = {
  CLOSE: 'Schlie&szlig;en',
  NEXT: 'Weiter',
  PREV: 'Zurück',
  ERROR:
    'Die angeforderten Daten konnten nicht geladen werden. <br/> Bitte versuchen Sie es sp&auml;ter nochmal.',
  TOGGLE_SLIDESHOW: 'Diaschau ',
  TOGGLE_FULLSCREEN: 'Vollbild',
  TOGGLE_THUMBS: 'Vorschaubilder',
  ITERATEZOOM: 'Zoom',
  DOWNLOAD: 'Herunterladen',
  SHARE: 'Teilen',
  ZOOM: 'Vergr&ouml;&szlig;ern',
}

Fancybox.bind('[data-learning-scenarios]', {
  Toolbar: {
    enabled: true,
    display: {
      right: ['close'],
    },
  },
  Html: {
    closeBtn: true,
  },
  Carousel: {
    Panzoom: false,
  },
  on: {
    close: () => {
      emitter.emit('close-learning-scenarios')
    },
  },
})
