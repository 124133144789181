<script setup>
import { ref, watch, onMounted } from 'vue'
import emblaCarouselVue from 'embla-carousel-vue'

const props = defineProps({
  selectedCase: {
    type: Object,
    default: () => ({}),
  },
  cases: {
    type: Array,
    default: () => [],
  },
  chapters: {
    type: Array,
    default: () => [],
  },
  instantSelectionSlideshow: {
    type: Boolean,
    default: false,
  },
  instantSelectionThumbnails: {
    type: Boolean,
    default: false,
  },
  download: {
    type: Boolean,
    default: false,
  },
})

const controlsVisible = ref(true)
const timeout = ref()
const timeoutDuration = 3000
const loadedImages = ref([])

const emit = defineEmits(['close', 'select-by-index'])

const selectedCaseIndex = ref(0)

const slideshowOptions = ref({
  containScroll: 'keepSnaps',
})

const [slideshow, slideshowApi] = emblaCarouselVue(slideshowOptions)

const thumbnailsOptions = ref({
  containScroll: 'keepSnaps',
  dragFree: true,
})

const [thumbnails, thumbnailsApi] = emblaCarouselVue(thumbnailsOptions)

const getRandomInteger = (from, to, without = []) => {
  const allowedNumbers = Array(to - from)
    .fill()
    .map((empty, index) => index + from)
    .filter((number) => !without.includes(number))

  return allowedNumbers[Math.floor(Math.random() * allowedNumbers.length)]
}

const hideWidget = function () {
  controlsVisible.value = false
}

const showWidget = function () {
  controlsVisible.value = true
  resetTimeout()
}

const resetTimeout = function () {
  clearTimeout(timeout.value)

  timeout.value = setTimeout(hideWidget, timeoutDuration)
}

const slideshowPrev = function () {
  if (slideshowApi.value) slideshowApi.value.scrollPrev()
}

const slideshowNext = function () {
  if (slideshowApi.value) slideshowApi.value.scrollNext()
}

const selectSlide = function (index) {
  if (slideshowApi.value && thumbnailsApi.value) {
    slideshowApi.value.scrollTo(index, props.instantSelectionSlideshow)
    thumbnailsApi.value.scrollTo(index, props.instantSelectionThumbnails)
    selectedCaseIndex.value = index + 1
  }
}

const selectThumbnail = function (index) {
  if (slideshowApi.value && thumbnailsApi.value) {
    emit('select-by-index', {
      index: index,
      instantSelectionSlideshow: true,
      instantSelectionThumbnails: false,
    })
  }
}

const goToRandomCase = function () {
  if (slideshowApi.value && thumbnailsApi.value) {
    emit('select-by-index', {
      index: getRandomInteger(0, props.cases.length - 1, [
        props.selectedCase.index,
      ]),
      instantSelectionSlideshow: false,
      instantSelectionThumbnails: false,
    })
  }
}

const changeInput = function () {
  if (selectedCaseIndex.value < 1) selectedCaseIndex.value = 1
  if (selectedCaseIndex.value > props.cases.length)
    selectedCaseIndex.value = props.cases.length

  if (isNaN(selectedCaseIndex.value)) selectedCaseIndex.value = 1
  emit('select-by-index', {
    index: selectedCaseIndex.value - 1,
    instantSelectionSlideshow: true,
    instantSelectionThumbnails: true,
  })
}

const imageLoaded = function (id) {
  loadedImages.value.push(id)
}

const getCurrentChapterIndex = function () {
  const filteredChapters = props.chapters.find((chapter) => {
    return (
      selectedCaseIndex.value >= chapter.rangeStart &&
      selectedCaseIndex.value <= chapter.rangeEnd
    )
  })

  return filteredChapters.index
}

const nextChapter = function () {
  const currentIndex = getCurrentChapterIndex()
  let newIndex = undefined

  if (currentIndex != props.chapters.length - 1) {
    newIndex = currentIndex + 1
  } else {
    newIndex = 0
  }

  emit('select-by-index', {
    index: props.chapters[newIndex].rangeStart - 1,
    instantSelectionSlideshow: true,
    instantSelectionThumbnails: false,
  })
}

const previousChapter = function () {
  const currentIndex = getCurrentChapterIndex()
  let newIndex = undefined

  if (selectedCaseIndex.value == props.chapters[currentIndex].rangeStart) {
    if (currentIndex != 0) {
      newIndex = currentIndex - 1
    } else {
      newIndex = props.chapters.length - 1
    }
  } else {
    newIndex = currentIndex
  }

  emit('select-by-index', {
    index: props.chapters[newIndex].rangeStart - 1,
    instantSelectionSlideshow: true,
    instantSelectionThumbnails: false,
  })
}

watch(
  () => props.selectedCase,
  (newCase) => {
    selectSlide(newCase.index)
  },
)

onMounted(() => {
  if (slideshowApi.value && thumbnailsApi.value) {
    selectSlide(props.selectedCase.index)

    slideshowApi.value.on('select', function () {
      emit('select-by-index', {
        index: slideshowApi.value.selectedScrollSnap(),
        instantSelectionSlideshow: false,
        instantSelectionThumbnails: false,
      })
    })
  }

  document.addEventListener('mousemove', showWidget)
  document.addEventListener('keydown', showWidget)
  document.addEventListener('scroll', showWidget)
  document.addEventListener('click', showWidget)

  resetTimeout()

  window.addEventListener('keydown', function (event) {
    if (event.keyCode == '37') {
      event.preventDefault()
      slideshowApi.value.scrollPrev()
    } else if (event.keyCode == '39') {
      event.preventDefault()
      slideshowApi.value.scrollNext()
    } else if (event.keyCode == '33') {
      event.preventDefault()
      slideshowApi.value.scrollPrev()
    } else if (event.keyCode == '34') {
      event.preventDefault()
      slideshowApi.value.scrollNext()
    }
  })
})
</script>

<template>
  <div class="LearningScenariosDetails">
    <button
      class="LearningScenariosDetails-backToOverview"
      @click="$emit('close')"
    >
      <i class="las la-angle-left"></i> Zurück zur Übersicht
    </button>
    <div ref="slideshow" class="LearningScenariosDetails-slideshow">
      <ul class="LearningScenariosDetails-slideshowContainer">
        <li
          v-for="(item, index) in cases"
          :key="'slideshow_' + item.id"
          class="LearningScenariosDetails-slideshowItem"
          :class="{
            'LearningScenariosDetails-slideshowItem--active':
              index == selectedCase.index,
          }"
        >
          <video
            v-if="item.video"
            class="LearningScenariosDetails-slideshowVideo"
            width="1920"
            height="1080"
            autoplay
            playsinline
            muted
            loop
          >
            <source :src="item.video" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <img
            v-if="item.image"
            :src="item.image.src"
            :alt="item.image.description"
            :width="item.image.width"
            :height="item.image.height"
            loading="lazy"
            :class="{
              'LearningScenariosDetails-slideshowImage--loaded':
                loadedImages.includes(item.id),
            }"
            class="LearningScenariosDetails-slideshowImage"
            @load="imageLoaded(item.id)"
          />
          <div
            v-if="item.image && !loadedImages.includes(item.id)"
            class="LearningScenariosDetails-slideshowLoader"
          >
            <img
              alt="Icon: Lupe"
              aria-hidden="true"
              class="LearningScenariosDetails-slideshowLoaderImage"
              :src="'/site/templates/dist/static/icon_searching.svg'"
            />

            Laden …
          </div>
        </li>
      </ul>

      <div
        :class="{
          'LearningScenariosDetails-slideshowWidget--hidden': !controlsVisible,
        }"
        class="LearningScenariosDetails-slideshowWidget"
      >
        <button
          class="LearningScenariosDetails-button"
          title="Vorheriges Kapitel"
          @click="previousChapter()"
        >
          <i aria-hidden="true" class="las la-step-backward"></i>
        </button>

        <button
          class="LearningScenariosDetails-button"
          title="Vorherige Lernsituation"
          @click="slideshowPrev()"
        >
          <i aria-hidden="true" class="las la-angle-left"></i>
        </button>

        <div class="LearningScenariosDetails-selection">
          Fall
          <input
            v-model="selectedCaseIndex"
            type="number"
            min="1"
            :max="props.cases.length"
            class="LearningScenariosDetails-input"
            @change="changeInput($event)"
          />
          von
          {{ cases.length }}
        </div>

        <a
          v-if="download && selectedCase.download"
          :href="selectedCase.download"
          :download="'mund-pflege-lernstituationen-fall-' + selectedCaseIndex"
          class="LearningScenariosDetails-button"
          title="Original herunterladen"
        >
          <i class="las la-download"></i>
        </a>

        <button
          class="LearningScenariosDetails-button"
          title="Zufällige Lernsituation"
          @click="goToRandomCase()"
        >
          <i class="las la-sync"></i>
        </button>

        <!-- <button
          title="Übersicht"
          class="LearningScenariosDetails-button"
          @click="$emit('close')"
        >
          <i class="las la-th"></i>
        </button> -->

        <button
          class="LearningScenariosDetails-button"
          title="Nächste Lernsituation"
          @click="slideshowNext()"
        >
          <i aria-hidden="true" class="las la-angle-right"></i>
        </button>

        <button
          class="LearningScenariosDetails-button"
          title="Nächstes Kapitel"
          @click="nextChapter()"
        >
          <i aria-hidden="true" class="las la-step-forward"></i>
        </button>
      </div>
    </div>

    <div
      ref="thumbnails"
      class="LearningScenariosDetails-thumbnails"
      :class="{
        'LearningScenariosDetails-thumbnails--hidden': !controlsVisible,
      }"
      aria-hidden="true"
    >
      <ul class="LearningScenariosDetails-thumbnailsContainer">
        <li
          v-for="(item, index) in cases"
          :key="'thumbnail_' + item.id"
          class="LearningScenariosDetails-thumbnailsItem"
          :class="{
            'LearningScenariosDetails-thumbnailsItem--active':
              index == selectedCase.index,
          }"
          @click="selectThumbnail(index)"
        >
          <img
            :src="item.thumbnail.src"
            :alt="item.thumbnail.description"
            :width="item.thumbnail.width"
            :height="item.thumbnail.height"
            loading="lazy"
            class="LearningScenariosDetails-thumbnailsImage"
          />
        </li>
        2
      </ul>
    </div>

    <portal v-if="props.selectedCase" to="solution">
      <!-- eslint-disable vue/no-v-html -->
      <div class="LearningScenariosDetails-solution info-text">
        <b class="LearningScenariosDetails-solutionHeading">Beobachtungen</b>
        <div v-html="props.selectedCase.observation"></div>
        <b class="LearningScenariosDetails-solutionHeading">Diagnose</b>
        <div v-html="props.selectedCase.diagnosis"></div>
        <b class="LearningScenariosDetails-solutionHeading">Maßnahmen</b>
        <div v-html="props.selectedCase.action"></div>
        <b class="LearningScenariosDetails-solutionHeading"
          >Weiterführende Informationen</b
        >
        <div
          class="LearningScenariosDetails-solutionLinks"
          v-html="props.selectedCase.links"
        ></div>
        <!--eslint-enable-->
      </div>
    </portal>

    <portal v-if="props.selectedCase.history" to="history">
      <!-- eslint-disable vue/no-v-html -->
      <div class="LearningScenarios-history">
        <b>Hinweise/Vorgeschichte</b>
        <div class="info-text" v-html="props.selectedCase.history"></div>
      </div>
      <!--eslint-enable-->
    </portal>
  </div>
</template>
