<script setup>
import { ref, nextTick, onMounted } from 'vue'

// eslint-disable-next-line
import LearningScenariosChapter from './LearningScenariosChapter.vue'
import LearningScenariosDetails from './LearningScenariosDetails.vue'
import emitter from '@/mitt'

const mainWrapper = ref()
const cases = ref(false)
const chapters = ref(false)
const instructions = ref(false)
const instructionSteps = ref(false)
const anatomy = ref(false)
const selectedCase = ref(undefined)
const activeTab = ref(1)
const instantSelectionSlideshow = ref(false)
const instantSelectionThumbnails = ref(false)
const view = ref('overview')
const mainScrollTop = ref(0)
const lastSelectedCase = ref(undefined)
const canUserDownloadOriginals = ref(false)

const openCase = function (newCase) {
  mainScrollTop.value = mainWrapper.value.scrollTop
  view.value = 'details'
  selectedCase.value = newCase
  instantSelectionSlideshow.value = true
  instantSelectionThumbnails.value = true
  mainWrapper.value.scrollTop = 0
  lastSelectedCase.value = undefined
  if (activeTab.value == 3) activeTab.value = 1
}

const selectCaseByIndex = function (event) {
  selectedCase.value = cases.value.find((item) => {
    return item.index == event.index
  })

  lastSelectedCase.value = selectedCase.value
  instantSelectionSlideshow.value = event.instantSelectionSlideshow
  instantSelectionThumbnails.value = event.instantSelectionThumbnails

  if (activeTab.value == 3) activeTab.value = 1
}

const goToOverview = function () {
  view.value = 'overview'
  selectedCase.value = undefined
  activeTab.value = 1

  if (lastSelectedCase.value === undefined) {
    nextTick().then(() => {
      mainWrapper.value.scrollTop = mainScrollTop.value
    })
  } else {
    scrollLastSelectedCaseIntoView()
  }
}

const scrollLastSelectedCaseIntoView = function () {
  chapters.value.forEach((chapter) => {
    if (chapter.id == lastSelectedCase.value.chapterId) chapter.open = true
  })

  nextTick().then(() => {
    const lastSelectedCaseElement = document.querySelector(
      '.LearningScenariosChapter-case[data-id="' +
        lastSelectedCase.value.id +
        '"]',
    )

    lastSelectedCaseElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    })
  })
}

const closeDialog = function () {
  goToOverview()
}

const toggleChapter = function (id) {
  const checkChapterWithId = chapters.value.find((item) => {
    return item.id == id
  })

  chapters.value.forEach((item) => {
    if (item.id == checkChapterWithId.id) item.open = !checkChapterWithId.open
  })
}

onMounted(() => {
  cases.value = document.jsVariables.learningScenarios.cases
  chapters.value = document.jsVariables.learningScenarios.chapters
  instructions.value = document.jsVariables.learningScenarios.instructions
  instructionSteps.value =
    document.jsVariables.learningScenarios.instructionSteps
  anatomy.value = document.jsVariables.learningScenarios.anatomy
  canUserDownloadOriginals.value =
    document.jsVariables.learningScenarios.download
  emitter.on('close-learning-scenarios', () => closeDialog())
})
</script>

<template>
  <div id="learning-scenarios-wrapper" class="LearningScenarios-wrapper">
    <main ref="mainWrapper" class="LearningScenarios-main">
      <div v-if="view == 'overview'" class="LearningScenarios-overview">
        <LearningScenariosChapter
          v-for="chapter in chapters"
          :key="chapter.id"
          :chapter="chapter"
          :cases="cases"
          :open="chapter.open"
          @toggle-chapter="toggleChapter(chapter.id)"
          @select-case="openCase($event)"
        ></LearningScenariosChapter>
      </div>
      <LearningScenariosDetails
        v-if="view == 'details'"
        :selected-case="selectedCase"
        :cases="cases"
        :chapters="chapters"
        :instant-selection-slideshow="instantSelectionSlideshow"
        :instant-selection-thumbnails="instantSelectionThumbnails"
        :download="canUserDownloadOriginals"
        @close="goToOverview()"
        @select-by-index="selectCaseByIndex($event)"
      >
      </LearningScenariosDetails>
    </main>
    <aside
      class="LearningScenarios-sidebar"
      :class="{ 'LearningScenarios-sidebar--caseSelected': selectedCase }"
    >
      <div class="LearningScenarios-tablist" role="tablist">
        <button
          id="tab-1"
          type="button"
          role="tab"
          class="LearningScenarios-tablistItem"
          :aria-selected="activeTab == 1"
          aria-controls="tabpanel-1"
          @click="activeTab = 1"
        >
          Aufgabe
        </button>
        <button
          id="tab-2"
          type="button"
          role="tab"
          class="LearningScenarios-tablistItem"
          :aria-selected="activeTab == 2"
          aria-controls="tabpanel-2"
          @click="activeTab = 2"
        >
          Anatomie
        </button>
        <span class="LearningScenarios-tablistSeperator"></span>
        <button
          id="tab-3"
          type="button"
          role="tab"
          class="LearningScenarios-tablistItem"
          :aria-selected="activeTab == 3"
          aria-controls="tabpanel-3"
          :disabled="!selectedCase"
          @click="activeTab = 3"
        >
          Lösung
        </button>
      </div>
      <div
        id="tabpanel-1"
        class="LearningScenarios-tabpanel"
        :class="{ 'LearningScenarios-tabpanel--active': activeTab == 1 }"
        role="tabpanel"
        aria-labelledby="tab-1"
      >
        <!-- eslint-disable vue/no-v-html -->
        <portal-target name="history"></portal-target>

        <div
          class="info-text LearningScenarios-instructions"
          v-html="instructions"
        ></div>

        <div v-for="(step, index) in instructionSteps" :key="index">
          <details
            class="LearningScenarios-details"
            :open="view == 'overview' ? true : false"
          >
            <summary class="LearningScenarios-detailsSummary">
              {{ index + 1 }}. {{ step.title }}
            </summary>
            <div class="info-text" v-html="step.text"></div>
          </details>
        </div>
        <!--eslint-enable-->
      </div>
      <div
        id="tabpanel-2"
        class="LearningScenarios-tabpanel"
        :class="{ 'LearningScenarios-tabpanel--active': activeTab == 2 }"
        role="tabpanel"
        aria-labelledby="tab-2"
      >
        <img
          v-for="(image, index) in anatomy.images"
          :key="index"
          class="LearningScenarios-sidebarImage"
          :src="image.src"
        />
      </div>
      <div
        id="tabpanel-3"
        class="LearningScenarios-tabpanel"
        :class="{ 'LearningScenarios-tabpanel--active': activeTab == 3 }"
        role="tabpanel"
        aria-labelledby="tab-3"
      >
        <portal-target name="solution"></portal-target>
      </div>
    </aside>
  </div>
</template>
