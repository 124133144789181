<script setup>
import { computed } from 'vue'

const props = defineProps({
  chapter: {
    type: Object,
    default: () => ({}),
  },
  cases: {
    type: Array,
    default: () => [],
  },
  open: {
    type: Boolean,
    default: false,
  },
})

const toggleChapter = function () {
  emit('toggleChapter')
}

const filteredCases = computed(() => {
  const filteredCases = props.cases.filter((item) => {
    return item.chapterId == props.chapter.id
  })

  return filteredCases
})

const emit = defineEmits(['select-case', 'toggleChapter'])
</script>

<template>
  <div class="LearningScenariosChapter">
    <div class="LearningScenariosChapter-header">
      <h2 class="LearningScenariosChapter-heading">
        <!-- eslint-disable vue/no-v-html -->
        <strong v-html="chapter.title"></strong>
        <!--eslint-enable-->
        ({{ chapter.rangeStart }}–{{ chapter.rangeEnd }})
      </h2>
      <button
        class="LearningScenariosChapter-toggle"
        :aria-expanded="props.open"
        @click="toggleChapter()"
      >
        <span>{{
          props.open
            ? 'Weniger Lernsituationen anzeigen'
            : 'Alle ' + chapter.caseCount + ' Lernsituationen anzeigen'
        }}</span>
      </button>
    </div>

    <div class="LearningScenariosChapter-casesWrapper">
      <ul class="LearningScenariosChapter-cases">
        <li
          v-for="singleCase in filteredCases"
          :key="singleCase.id"
          class="LearningScenariosChapter-case"
          :data-id="singleCase.id"
        >
          <button
            class="LearningScenariosChapter-caseButton"
            @click="$emit('select-case', singleCase)"
          >
            <span class="LearningScenariosChapter-caseCount">{{
              singleCase.index + 1
            }}</span>
            <img
              v-if="singleCase.thumbnail"
              class="LearningScenariosChapter-caseImage"
              :src="singleCase.thumbnail.src"
              :width="singleCase.thumbnail.width"
              :height="singleCase.thumbnail.height"
              loading="lazy"
            />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
